import React from 'react';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Div from '@vkontakte/vkui/dist/components/Div/Div';

/* eslint-disable no-restricted-globals */

class Home extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isExtenstionAvailable: false
		}
	}

	componentDidMount () {
		window.addEventListener('message', (e) => {
			console.log(e)
			if (e.data === 'pong') {
				this.setState({ isExtenstionAvailable: true })
				parent.postMessage({ type: 'params', data: window.location.search.slice(1) }, '*')
			}
		})
		this.ping()
	}

	ping () {
		parent.postMessage({ type: 'ping' }, '*')
	}

	render () {
		return (
			<Panel id={this.props.id}>
				<PanelHeader>VK Remote</PanelHeader>
				<Group>
					<Div>
						<Cell asideContent={this.state.isExtenstionAvailable ? 'Есть' : 'Нет'}>
							Проверка расширения...
						</Cell>
						{this.state.isExtenstionAvailable && (
							<Cell>Параметры запуска были переданы. Кажется, приложение можно закрывать.</Cell>
						)}
					</Div>
				</Group>
			</Panel>
		)
	}
}

export default Home;
